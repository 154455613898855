import { CubeType } from '../interfaces/container';

export const containerStatusFilterItems = [
  {
    value: 'ALL',
    label: 'Все',
    aggKey: 'count',
  },
  {
    value: 'У клиента',
    label: 'У клиента',
    aggKey: 'count_at_client',
  },
  {
    value: 'На базе',
    label: 'На базе',
    aggKey: 'count_at_base',
  },
];

export const CONTAINER_STATUS_MAP: any = {
  'На базе': 'on-base',
  'У клиента': 'at-client',
};

export const containerVolumeFilterItems = [
  {
    value: '8',
    label: '8 кубов',
    aggKey: 'count_8',
  },
  {
    value: '15',
    label: '15 кубов',
    aggKey: 'count_15',
  },
  {
    value: '18',
    label: '18 кубов',
    aggKey: 'count_18',
  },
  {
    value: '27',
    label: '27 кубов',
    aggKey: 'count_27',
  },
  {
    value: '35',
    label: '35 кубов',
    aggKey: 'count_35',
  },
];

export const cubeType: CubeType[] = ['8', '15', '18', '27', '35'];
