import {
  Autocomplete as AutocompleteMui,
  FilterOptionsState,
  Stack,
  TextField as TextFieldMui,
  styled,
} from '@mui/material';
import { FC } from 'react';

type OptionObj = { id: number; label: string };
export type Option = string | OptionObj;

type SelectOutLabel = React.ComponentProps<typeof TextFieldMui> & {
  list: Option[];
  label: string;
  defaultValue?: Option;
  onChangeValue?: (value: string) => void;
  onChangeId?: (id: string) => void;
  w?: number;
  bg?: string;
  loading?: boolean;
  filterOptions?: (options: Option[], state: FilterOptionsState<Option>) => Option[];
};

const Autocomplete = styled(AutocompleteMui<Option, false, false, false>)<
  Omit<SelectOutLabel, 'list' | 'label'>
>(({ w }) => ({
  height: 'var(--input-height)',
  width: `${w}px`,
  '.MuiFormControl-root': {
    height: '100%',
  },
  '.MuiInputBase-root': {
    height: '100%',
  },
}));
const TextField = styled(TextFieldMui)(() => ({
  '.MuiInputBase-root': {
    borderRadius: '10px',
  },
  fieldset: {
    boxShadow: '4px 3px 2px #F6F6F9',
    borderColor: 'var(--input-border-color)',
  },
}));

const Label = styled('label')(() => ({
  fontSize: '12px',
  fontWeight: 400,
}));

const equalityChack = (option: unknown, value: unknown) => {
  if (typeof option === 'string' && typeof value === 'string') {
    return option === value;
  }
  if (
    typeof option === 'object' &&
    option !== null &&
    typeof value === 'object' &&
    value !== null &&
    'id' in option &&
    'id' in value
  ) {
    return option.id === value.id;
  }
  return false;
};

export const SelectOutLabel: FC<SelectOutLabel> = ({
  list,
  w,
  bg,
  defaultValue,
  onChangeValue,
  onChangeId,
  label,
  loading,
  filterOptions,
  ...props
}) => {
  return (
    <Stack gap={1.2}>
      <Label>{label}</Label>
      <Autocomplete
        disablePortal
        loading={loading}
        options={list}
        defaultValue={defaultValue}
        w={w}
        bg={bg}
        filterOptions={filterOptions}
        isOptionEqualToValue={equalityChack}
        onInputChange={(_, value) => {
          if (onChangeValue) {
            onChangeValue(value);
          }
          if (onChangeId) {
            if (Array.isArray(list)) {
              const filteredItem = list.find((item): item is OptionObj => {
                return typeof item === 'object' && item !== null && item.label === value;
              });
              if (filteredItem) {
                onChangeId(String(filteredItem.id));
              } else {
                onChangeId('');
              }
            }
          }
        }}
        renderInput={(params) => <TextField {...params} {...props} />}
      />
    </Stack>
  );
};
