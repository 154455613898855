import { ReqResult, ReqStatus, RouteConfig } from '../interfaces/req';

export const reqStatus: ReqStatus[] = [
  'В работе',
  'Водитель назначен',
  'Завершена',
  'Запланирована',
  'Новая',
  'Отменена',
  'ЧП',
];

export const routeConfig: RouteConfig = {
  'Поставить - Забрать': [
    {
      label: 'Адрес куда необходимо поставить и откуда забрать контейнер',
      hasContainer: true,
    },
    {
      label: 'Адрес загрузки контейнера',
      hasContainer: true,
      disabled: true,
    },
  ],
  Поставить: [
    {
      label: 'Адрес куда необходимо поставить контейнер',
      hasContainer: true,
    },
    {
      label: 'Адрес загрузки контейнера',
      hasContainer: false,
    },
  ],
  Забрать: [
    {
      label: 'Адрес откуда необходимо забрать контейнер',
      hasContainer: true,
    },
  ],
  'База - Полигон': [
    {
      label: 'Адрес откуда необходимо забрать контейнер',
      hasContainer: true,
    },
  ],
};

export const REQ_TABLE_FIELDS = [
  {
    mykey: 'status',
    label: 'Статус',
  },
  {
    mykey: 'paid',
    label: 'Оплата',
  },
  {
    mykey: 'client',
    label: 'Клиент',
  },
  {
    mykey: 'container',
    label: 'Контейнер',
  },
  {
    mykey: 'car',
    label: 'Автомашина',
  },
  {
    mykey: 'date',
    label: 'Дата заявки',
  },
  {
    mykey: 'tel',
    label: 'Телефон',
  },
  {
    mykey: 'type',
    label: 'Тип заявки',
  },
];

export type ReqFields = {
  value: string;
  label: string;
  aggKey: keyof Omit<Partial<ReqResult>, 'results'>;
};

export const REQ_PAYMENT_KIND: ReqFields[] = [
  {
    value: 'Не оплачен',
    label: 'Неоплаченные',
    aggKey: 'not_paid_count',
  },
  {
    value: 'Оплачен',
    label: 'Оплаченные',
    aggKey: 'paid_count',
  },
];

export const REQ_STATUS_FILTER_ITEMS: ReqFields[] = [
  {
    value: 'ALL',
    label: 'Все',
    aggKey: 'count',
  },
  {
    value: 'Новая',
    label: 'Новые',
    aggKey: 'new_count',
  },
  {
    value: 'Водитель назначен',
    label: 'Водитель назначен',
    aggKey: 'driver_appointed_count',
  },
  {
    value: 'В работе',
    label: 'В работе',
    aggKey: 'in_work_count',
  },
  {
    value: 'Завершена',
    label: 'Завершены',
    aggKey: 'finished_count',
  },
];

export const REQ_DATE_FILTER_ITEMS = [
  {
    value: 'today',
    label: 'Сегодня',
    dayStart: 0,
    dayEnd: 0,
    withoutDate: false,
  },
  {
    value: 'yesterday',
    label: 'Вчера',
    dayStart: 1,
    dayEnd: 1,
    withoutDate: false,
  },
  {
    value: 'week_ago',
    label: 'Неделя',
    dayStart: 0,
    dayEnd: 7,
    withoutDate: false,
  },
  {
    value: 'month_ago',
    label: 'Месяц',
    dayStart: 0,
    dayEnd: 30,
    withoutDate: false,
  },
  {
    value: 'year_ago',
    label: 'Год',
    dayStart: 0,
    dayEnd: 365,
    withoutDate: false,
  },
  {
    value: 'without_date',
    label: 'Без даты',
    dayStart: 0,
    dayEnd: 0,
    withoutDate: true,
  },
];
