import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const Box = styled('div')(() => ({
  height: '24px',
  maxWidth: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  borderRadius: '12px',
  backgroundColor: '#232323',
  color: '#fff',
  fontSize: '10px',
}));

export const BadgeRouteStatus: FC<PropsWithChildren> = ({ children }) => {
  return <Box>{children}</Box>;
};
