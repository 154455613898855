import { Button, Stack, styled } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { Group } from '../../../../../ui/mui/Group/Group';
import { useAddFormContext } from '../../../../../hooks/Context/useAddFormContext';
import { SelectOutLabel } from '../../../../../ui/mui/Select/SelectOutLabel';
import { InputOutLabal } from '../../../../../ui/mui/Input/InputOutLabal';
import { useClientSelect } from '../../../../../hooks/Reqs/useClientSelect';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useContainerSelect } from '../../../../../hooks/Reqs/useContainerSelect';
import { SelectForm } from '@/ui';
import { GARBAGE_COLLECTION } from '@/constants';

enum RouteType {
  PUT_AND_TAKE = 'Поставить - Забрать',
  PUT = 'Поставить',
  TAKE = 'Забрать',
  BASE_AND_POLYGON = 'База - Полигон',
}

type SelectList = {
  id: number;
  label: string;
};

type ContainerAction = {
  label: string;
  onChange: (value: string) => void;
  list?: { id: number; label: string }[];
};
type SectionStates = {
  [key in RouteType]: {
    address_label: string;
    container_actions: ContainerAction[];
    isAddress_loading: boolean;
    unloading_point: boolean;
  };
};

const ElementAction = styled('div')(() => ({
  width: '150px',
  height: '40px',
  backgroundColor: '#D9D9D9',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
}));

const ContainerAction: React.FC<ContainerAction> = React.memo(({ label, list, onChange }) => {
  return (
    <Stack gap={5}>
      <ElementAction>{label}</ElementAction>
      <SelectOutLabel
        label="Выберите контейнер"
        list={list ?? []}
        w={245}
        placeholder="Введите номер контейнера"
        onChangeValue={onChange}
        loading={!list?.length}
      />
    </Stack>
  );
});

ContainerAction.displayName = 'ContainerAction';

export const ActionSection = () => {
  const { formData, updateFormData } = useAddFormContext();
  const { addresses_objects, company } = useClientSelect(formData?.client ?? null);
  const { container_numbers, containers } = useContainerSelect();
  const [addressLoading, setAddressLoading] = useState<string>('');
  const [object_container_numbers, setObject_container_numbers] = useState<SelectList[]>([]);
  const containersRef = useRef(containers);

  useEffect(() => {
    containersRef.current = containers;
  }, [containers]);

  const handleTakeContainer = useCallback(
    (value: string) => {
      if (!value) return;
      const mod_value = value.split('~')[0];
      const container_id = containersRef.current?.find(
        (container) => container.num === mod_value
      )?.id;

      if (container_id) {
        updateFormData('take_container')(container_id);
      }
    },
    [updateFormData]
  );

  const handleSelectContainer = useCallback(
    (value: string) => {
      if (!value) return;
      const mod_value = value.split('~')[0];

      const container_id = containersRef.current?.find(
        (container) => container.num === mod_value
      )?.id;

      const filtered_container = containersRef.current?.filter(
        (container) => container.id === container_id
      );
      if (filtered_container) {
        const last_address = filtered_container[0]?.last_active_req_location;
        const current_address = filtered_container[0]?.location;

        if (last_address) {
          setAddressLoading(last_address);
        } else if (current_address) {
          setAddressLoading(current_address);
        } else {
          setAddressLoading('');
        }
      }

      updateFormData('container')(container_id);
    },
    [updateFormData]
  );

  const getContainersByAddress = useCallback(
    (select_address: string) => {
      const filteredCompanyObject =
        company?.company_objects?.filter((obj) => obj.address === select_address) ?? [];

      updateFormData('company_object')(filteredCompanyObject[0]?.id);
      updateFormData('address_coordinates')(
        filteredCompanyObject[0]?.coordinates ?? '55.796127, 49.106414'
      );

      const containers = filteredCompanyObject.flatMap((company) => company.containers) || [];
      const selectList = containers.map((container) => ({
        id: container.id,
        label: `#${container.num} (тип - ${container.kind})`,
      }));

      setObject_container_numbers(selectList);
    },
    [company?.company_objects, updateFormData]
  );

  const sectionStates: SectionStates = useMemo(
    () => ({
      [RouteType.PUT_AND_TAKE]: {
        address_label: 'Адрес куда необходимо поставить и откуда забрать контейнер',
        container_actions: [
          {
            label: 'Поставить',
            onChange: (value) => handleSelectContainer(value),
          },
          {
            label: 'Забрать',
            onChange: (value) => handleTakeContainer(value),
          },
        ],
        isAddress_loading: true,
        unloading_point: true,
      },
      [RouteType.PUT]: {
        address_label: 'Адрес куда необходимо поставить  контейнер',
        container_actions: [
          {
            label: 'Поставить',
            onChange: (value) => handleSelectContainer(value),
          },
        ],
        isAddress_loading: true,
        unloading_point: false,
      },
      [RouteType.TAKE]: {
        address_label: 'Адрес откуда необходимо забрать контейнер',
        container_actions: [
          {
            label: 'Забрать',
            onChange: (value) => handleSelectContainer(value),
          },
        ],
        isAddress_loading: false,
        unloading_point: true,
      },
      [RouteType.BASE_AND_POLYGON]: {
        address_label: 'Адрес откуда необходимо забрать контейнер',
        container_actions: [
          {
            label: 'Выгрузить',
            onChange: (value) => handleSelectContainer(value),
          },
        ],
        isAddress_loading: false,
        unloading_point: true,
      },
    }),
    [handleSelectContainer, handleTakeContainer]
  );

  const sectionState = useMemo(() => {
    if (!sectionStates[formData?.kind as RouteType]) {
      console.error(`Неизвестный тип маршрута: ${formData?.kind}`);
      return null;
    }
    return sectionStates[formData?.kind as RouteType];
  }, [formData?.kind, sectionStates]);

  const filteredGarbageCollection = useMemo(
    () =>
      formData?.kind === 'База - Полигон'
        ? GARBAGE_COLLECTION.filter((item) => item !== 'База')
        : GARBAGE_COLLECTION,
    [formData?.kind]
  );

  if (!sectionState) return null;

  return (
    <Stack gap={3}>
      <Title variant="h4">Действия по заявке</Title>
      <Group gap={100}>
        <Stack>
          <SelectOutLabel
            label={sectionState.address_label}
            list={addresses_objects ?? []}
            w={350}
            placeholder="Выберите адрес компании"
            onChangeValue={(value) => {
              updateFormData('location')(value);
              updateFormData('address_location')(value);
              getContainersByAddress(value);
            }}
          />
          <Button
            href={`/companies/${formData?.client}/edit/`}
            target="_blank"
            rel="noopener noreferrer"
            variant="text"
            disabled={!formData?.client}
            sx={{
              width: '220px',
              textTransform: 'capitalize',
              textDecoration: 'underline',
              fontSize: '14px',
              fontWeight: 700,
              color: '#000',
              justifyContent: 'flex-start',
            }}
          >
            Добавить адрес объекта
          </Button>
        </Stack>
        <Group gap={70}>
          {sectionState.container_actions.map((action) => (
            <ContainerAction
              key={action.label}
              list={
                action.label === 'Забрать' || action.label === 'Выгрузить'
                  ? object_container_numbers
                  : container_numbers
              }
              {...action}
            />
          ))}
        </Group>
      </Group>
      {sectionState.isAddress_loading && (
        <InputOutLabal label="Адрес загрузки контейнера" w={350} disabled value={addressLoading} />
      )}
      {sectionState.unloading_point && (
        <SelectForm
          w={400}
          list={filteredGarbageCollection}
          label="Пункт вывоза мусора"
          onChange={(e) => updateFormData('unloading_point')(e.target.value)}
        />
      )}
    </Stack>
  );
};
