import { useState, useEffect, MouseEvent, ChangeEvent } from 'react';
import { Container } from '../../interfaces/container';
import SETTINGS from '../../settings';
import { exportFileFatch, myFetch } from '../../utils';
import './ContainerList.css';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import {
  containerVolumeFilterItems,
  containerStatusFilterItems,
  CONTAINER_STATUS_MAP,
} from '../../params/container';
import ModalTableFields from '../../components/ModalTableFields';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Select } from '../../components/select/Select';
import { downloadFile } from '../../utils/downloadFile';
import { logout } from '@/utils/logout';
import { Pagination } from '@/components';

const containerTableFields = [
  {
    mykey: 'status',
    label: 'Статус',
  },
  {
    mykey: 'volume',
    label: 'Объем',
  },
  {
    mykey: 'address',
    label: 'Адрес',
  },
  {
    mykey: 'date',
    label: 'Дата установки',
  },
  {
    mykey: 'phone',
    label: 'Телефон',
  },
  {
    mykey: 'order',
    label: 'Заказ',
  },
];

export default function ContainerList() {
  const crs1: Container[] = [];
  const navigate = useNavigate();
  const baseCounts: any = {
    count: 0,
    count_at_base: 0,
    count_at_client: 0,
  };

  const { me }: { me: any } = useOutletContext();

  const [counts, setCounts] = useState(baseCounts);
  const [containers, setContainers] = useState(crs1);
  const [currentStatus, setCurrentStatus] = useState('ALL');
  const [currentVolume, setCurrentVolume] = useState('ALL');

  const [query, setQuery] = useState('');

  const [page, setPage] = useState<number>(0);
  const [page_size, setPageSize] = useState(10);
  const [count, setCount] = useState(0);

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/containers/?status=${currentStatus}&kind=${currentVolume}&query=${query}&page=${page + 1}&page_size=${page_size}`,
        {},
        navigate
      );
      const _json = await res?.json();
      const {
        results,
        count,
        count_at_base,
        count_at_client,
        count_8,
        count_15,
        count_18,
        count_27,
        count_35,
      } = _json;
      const crs: Container[] = results;
      setContainers(crs);
      setCounts({
        count,
        count_at_base,
        count_at_client,
        count_8,
        count_15,
        count_18,
        count_27,
        count_35,
      });
      setCount(count);
    }
    fetchData();
  }, [navigate, currentStatus, currentVolume, query, page_size, page]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);

  const tableFieldsString = localStorage.getItem('containerTableFields');
  let tableFieldsObj;
  if (tableFieldsString) {
    tableFieldsObj = JSON.parse(tableFieldsString);
  } else {
    tableFieldsObj = {};
  }
  const [tableFieldIsClosed, setTableFieldIsClosed]: [any, (state: any) => void] =
    useState(tableFieldsObj);

  const handleExportFile = async () => {
    const params = {
      status: currentStatus,
      volume: currentVolume,
    };
    const path = location.pathname.replace(/\/+/g, '');
    const response = await exportFileFatch(path, navigate, params);
    try {
      await downloadFile(response, path, 'xlsx');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="containers-page">
        <ModalTableFields
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          state={tableFieldIsClosed}
          setState={setTableFieldIsClosed}
          tableFields={containerTableFields}
        />
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">Инвентаризация</span>
            <span
              className="page-top__left-settings icon-setting transition"
              onClick={openModal}
            ></span>
          </div>
          <div className="page-top__right d-flex items-center">
            {/* <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setDateKind('by_date');
                    setStartDate(date!);
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd.MM.YYYY"
                  shouldCloseOnSelect
                  // showYearPicker
                />
                &nbsp;-&nbsp;
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date!);
                    setDateKind('by_date');
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd.MM.YYYY"
                  shouldCloseOnSelect
                  // showYearPicker
                />
              </>
            </div> */}

            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                placeholder="Поиск по номеру контейнера..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{' '}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="/lk" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <span
                    className="dispatcher-menu__item transition"
                    onClick={() => logout(navigate)}
                  >
                    Выйти
                  </span>
                </div>
              </div>
            </div>

            <NavLink
              to={'/containers/add/'}
              className="page-top__button d-flex items-center transition"
            >
              Добавить контейнер
            </NavLink>
          </div>
        </div>
        <div className="page-content">
          <div className="containers-page__top d-flex items-center justify-between pr-10">
            <div className="containers-page__filter d-flex">
              {containerStatusFilterItems.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  const classList =
                    'containers-page__filter-item d-flex items-center transition' +
                    (item.value === currentStatus ? ' is-active' : '');
                  return (
                    <div
                      key={item.label}
                      className={classList}
                      onClick={() => {
                        setCurrentStatus(item.value);
                        setCurrentVolume('ALL');
                        // setCurrentPaymentStatus('ALL')
                      }}
                    >{`${item.label} · ${counts[item.aggKey]}`}</div>
                  );
                }
              )}
              {containerVolumeFilterItems.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  const classList =
                    'containers-page__filter-item d-flex items-center transition ' +
                    (item.value === currentVolume ? 'is-active' : '');
                  return (
                    <div
                      key={item.label}
                      className={classList}
                      onClick={() => {
                        setCurrentVolume(item.value);
                      }}
                    >
                      {`${item.label} `}
                      {/* {`${item.label} · ${counts[item.aggKey]}`} */}
                    </div>
                  );
                }
              )}
            </div>
            <Box>
              <Select
                onChange={handleExportFile}
                itemList={['Экспорт в xlsx']}
                value={''}
                label={'Действия'}
              />
            </Box>
          </div>

          <div className="containers-page__table">
            <table className="containers-table table">
              <thead className="table__top">
                <tr className="table__top-tr">
                  <th className="table__top-th th-id">
                    <div className="d-flex items-center">
                      <label className="table__checkbox">
                        <input type="checkbox" className="table__checkbox-input" />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      ID
                    </div>
                  </th>
                  {!tableFieldIsClosed.status && (
                    <th className="table__top-th th-status">Статус</th>
                  )}
                  {!tableFieldIsClosed.volume && (
                    <th className="table__top-th th-type">Объем контейнера</th>
                  )}
                  <th className="table__top-th th-number">Номер конт.</th>
                  {!tableFieldIsClosed.address && (
                    <th className="table__top-th th-address">Адрес объекта</th>
                  )}
                  {!tableFieldIsClosed.date && (
                    <th className="table__top-th th-date">Дата установки</th>
                  )}
                  {!tableFieldIsClosed.phone && (
                    <th className="table__top-th th-phone">Номер телефона</th>
                  )}
                  {!tableFieldIsClosed.order && <th className="table__top-th th-order">Заказ</th>}
                  <th className="table__top-th th-actions">Действия</th>
                </tr>
              </thead>
              <tbody>
                {containers?.map((container: Container) => {
                  return (
                    <tr className="table__row" key={container.id}>
                      <td className="table__td td-id">
                        <div className="table__td-inner d-flex items-center">
                          <label className="table__checkbox">
                            <input type="checkbox" className="table__checkbox-input" />
                            <div className="table__checkbox-block d-flex block-center transition">
                              <span className="icon-check"></span>
                            </div>
                          </label>
                          {container.id}
                        </div>
                      </td>
                      {!tableFieldIsClosed.status && (
                        <td className="table__td td-status">
                          <div className="table__td-inner d-flex items-center">
                            <div
                              className={`td-status__block d-flex items-center ${
                                CONTAINER_STATUS_MAP[container.status]
                              }`}
                            >
                              {/* classes for statuses: at-client - У клиента, on-base - На базе */}
                              {container.status}
                            </div>
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.volume && (
                        <td className="table__td td-type">
                          <div className="table__td-inner d-flex items-center">
                            {container.volume}
                          </div>
                        </td>
                      )}

                      <td className="table__td td-number">
                        <div className="table__td-inner d-flex items-center">{container.num}</div>
                      </td>

                      {!tableFieldIsClosed.address && (
                        <td className="table__td td-address">
                          <div className="table__td-inner d-flex items-center">
                            {/* Казань, ул. Седова, 27 */}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.date && (
                        <td className="table__td td-date">
                          <div className="table__td-inner d-flex items-center">
                            {/* 15 май 2024 */}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.phone && (
                        <td className="table__td td-phone">
                          <div className="table__td-inner d-flex items-center">
                            {/* +7 943 434-05-63 */}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.order && (
                        <td className="table__td td-order">
                          <div className="table__td-inner d-flex items-center">
                            {/* #3050 · ОАО СтройСмарт */}
                          </div>
                        </td>
                      )}
                      <td className="table__td td-actions">
                        <div className="table__td-inner d-flex items-center">
                          <div
                            className="td-actions__block d-flex items-center transition"
                            onClick={() =>
                              navigate('/', {
                                state: {
                                  key: 'container',
                                  data: { container_id: container.id },
                                },
                              })
                            }
                          >
                            <span className="icon-map transition"></span>
                            На карте
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href={`/containers/${container.id}/edit/`}
                          className="table__edit circle-center transition"
                        >
                          <span className="icon-edit"></span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            state={{ count, page, page_size }}
            action={{
              onChangePage: handleChangePage,
              onChangePageSize: handleChangePageSize,
            }}
          />
        </div>
      </div>
    </>
  );
}
